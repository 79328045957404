import React, { useState } from "react";
import "./Post.css";
import FeedHeaderCard from "../../feedcard/FeedHeaderCard";
import Avatar from "../../../assets/watch-movie.png";
import { AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa6";
import Modal from "../../common/modal/Modal";
import CommentBox from "../CommentBox/CommentBox";

const Post = ({ feed, handleLikePress, setShowHeader, handleAddComment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const showComentBox = () => {
    setShowHeader(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowHeader(true);
  };

  return (
    <div className="post">
      <Modal
        show={isModalOpen}
        onClose={closeModal}
        contentWidth={"100%"}
        contentHeight={"100vh"}
        showCloseBtn={false}
      >
        <CommentBox
          onClose={closeModal}
          post={feed}
          handleAddComment={handleAddComment}
        />
      </Modal>
      <FeedHeaderCard feed={feed} />
      <p className="post-content">{convertToPlain(feed?.content)}</p>
      {/* {video_url ? (
        <video width="100%" controls>
          <source src={video_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        // Fallback to display content if there's no video
        <p>{content}</p>
      )} */}
      {feed.media_type === "image" ? (
        <> {feed?.image_url && <img src={feed?.image_url} />}</>
      ) : (
        <video width="100%" height={"400px"} controls>
          <source src={feed?.image_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="activity-wrapper">
        <div
          className="activity-btns like"
          onClick={() => handleLikePress(feed?.id)}
        >
          <AiFillLike />
          <p>{feed?.likes_count} likes</p>
        </div>
        <div className="activity-btns like" onClick={showComentBox}>
          <FaRegComment />
          <p>{feed?.comments?.length} comments</p>
        </div>
      </div>
    </div>
  );
};

export default Post;
