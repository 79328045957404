import React, { useEffect, useRef, useState } from "react";
import "./CommentBox.css";
import { AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa6";
import userAvatar from "../../../assets/icons/user.png";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import Loader from "../../common/Loader/Loader";

const convertToPlain = (html) => {
  // Converts HTML content to plain text
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

const Icon = ({ children, onClick }) => (
  <button className="commentbox-icon-button" onClick={onClick}>
    {children}
  </button>
);

const Avatar = ({ src, alt }) => (
  <div className="comment-box-avatar ">
    <img src={src?.image_url} alt={alt || userAvatar} />
  </div>
);

const PostHeader = ({ post }) => (
  <div>
    <div className="post-header">
      <Avatar src={post?.users} alt="Profile" />
      <div className="user-info">
        <div className="user-name">{`${post?.users?.firstname || ""} ${
          post?.users?.lastname || ""
        }`}</div>
        <div className="timestamp">
          {post?.time_ago || "Unknown time"} ·{" "}
          {post?.hobbies?.name || "General"} · {post?.visibility || "Public"}
        </div>
      </div>
      <Icon>⋯</Icon>
    </div>
    <p className="post-content">{convertToPlain(post?.content || "")}</p>
  </div>
);

const EngagementStats = ({ post }) => (
  <div className="engagement-stats">
    <div className="like-count">
      <AiFillLike />
      <span>{post?.likes_count || 0}</span>
    </div>
    <div>{post?.comments?.length || 0} comments</div>
  </div>
);

const ActionButtons = () => (
  <div className="action-buttons">
    <button className="action-button">
      <AiFillLike /> Like
    </button>
    <button className="action-button">
      <FaRegComment /> Comment
    </button>
    {/* <button className="action-button">Share</button> */}
  </div>
);

const Comment = ({ me, content, timestamp }) => (
  <div className="comment">
    <Avatar src={me} alt="Commenter" />
    <div className="comment-content">
      <div className="comment-bubble">
        <div className="user-name">{`${me?.firstname} ${me?.lastname}`}</div>
        <div>{content}</div>
      </div>
      <div className="comment-actions">
        <span style={{marginLeft:10}}>{timestamp}</span>
        {/* <button className="action-link">Like</button>
        <button className="action-link">Reply</button> */}
      </div>
    </div>
  </div>
);

const CommentInput = ({
  me,
  post,
  commentValue,
  setCommentValue,
  // handleAddComment,
  handleAddNewComment,
  scrollToBottom,
  postComment,
}) => {
  const handleSubmit = () => {
    if (commentValue.trim()) {
      const newCommentObj = {
        id: (post?.comments?.length || 0) + 1,
        content: commentValue,
        users: post.users.image_url,
        post_id: post.id,
        user_id: me.id,
        timestamp: "Just now",
      };

      // Add the new comment to the local state
      handleAddNewComment(newCommentObj);

      // Post the comment to the backend
      postComment(post?.id, newCommentObj);

      // Reset the input field and scroll to the bottom
      setCommentValue("");
      scrollToBottom();
    }
  };

  return (
    <div className="comment-input">
      <Avatar src={me} alt="User" />
      <input
        type="text"
        placeholder="Write a comment..."
        className="input"
        value={commentValue || ""}
        onChange={(e) => setCommentValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission or newline
            handleSubmit();
          }
        }}
      />
      <p onClick={handleSubmit} style={{ cursor: "pointer" }}>
        Send
      </p>
    </div>
  );
};

const CommentBox = ({ onClose, post, handleAddComment }) => {
  const [commentValue, setCommentValue] = useState("");
  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);
  const commentsEndRef = useRef(null);

  useEffect(() => {
    const localUser = localStorage.getItem("user");
    setUser(JSON.parse(localUser));
  }, []);

  useEffect(() => {
    scrollToBottom(); // Ensure scroll is at the bottom initially
  }, [comments]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const response = await axios.get(
          `${BASE_URL}/posts/${post?.id}/comments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { comments } = response.data;
        setComments(comments);
      } catch (error) {
        console.log(error);
        // message.error("Error fetching data: No Connection");
      } finally {
      }
    };

    fetchComments();
  }, []);

  // Scroll to the last comment
  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const postComment = async (postId, commentText) => {
    // const showLoading = message.loading("Submitting your comment...", 0);
    // setSubmitting(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        // message.error("You need to be logged in to post a comment.");
        return;
      }

      const response = await axios.post(
        `${BASE_URL}/comments`,
        {
          post_id: commentText.post_id,
          user_id: commentText.user_id,
          content: commentText.content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newComment = response.data;

      // setComments((prevComments) => [newComment, ...prevComments]);
      // message.success("Comment posted successfully!");
    } catch (error) {
      console.error(error);
      // message.error("Error posting comment: No Connection");
    } finally {
      // showLoading();
      // setSubmitting(false);
    }
  };

  const handleAddNewComment = (newComment) => {
    // Update the comments state by appending the new comment
    setComments((prevComments) => [...prevComments, newComment]);
  };

  return (
    <div className="commentbox-container">
      <div className="commentbox-header">
        <div className="header-left" onClick={onClose}>
          <Icon>✕</Icon>
        </div>
      </div>

      <div className="commentbox-main-content">
        <div className="left-section">
          <div className="image-container">
            {post?.media_type === "image" ? (
              post?.image_url ? (
                <img src={post.image_url} alt="Post" className="post-image" />
              ) : null
            ) : (
              <video width="100%" height="600px" controls>
                <source src={post?.image_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>

        <div className="right-section">
          <PostHeader post={post} />
          <EngagementStats post={post} />
          <ActionButtons />

          <div className="comments">
            {comments.length === 0 ? (
              <div style={{paddingTop:50}}>
                <Loader width={50} height={50} />
              </div>
            ) : (
              <>
                {comments?.map((comment, index) => (
                  <>
                    <Comment
                      key={index}
                      me={user}
                      content={comment?.content || "No content"}
                      timestamp={comment?.time_ago || "Just now"}
                    />
                    <div ref={commentsEndRef} />
                  </>
                ))}
              </>
            )}
          </div>
          <CommentInput
            postComment={postComment}
            scrollToBottom={scrollToBottom}
            commentValue={commentValue}
            setCommentValue={setCommentValue}
            post={post}
            me={user}
            comments={comments}
            handleAddNewComment={handleAddNewComment}
            // handleAddComment={handleAddComment}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
